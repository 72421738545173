import React from "react"
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import {StaticImage} from "gatsby-plugin-image";
import SetupItem from "../components/elements/setup-item";
import openIcon from "../images/sections/setup-section/1-open.svg";
import openSentry from "../images/sections/setup-section/2-sentry.svg";
import openRelayer from "../images/sections/setup-section/3-relayer.svg";
import openValidator from "../images/sections/setup-section/4-validator.svg";
import openBackup from "../images/sections/setup-section/5-backup.svg";
import ContactSection from "../components/sections/contact-section";

const ValidatorPage = () => {
    return (
        <Layout id={'content-page'}>
            <Helmet>
                <title>SG-1.online - Validator Set-up</title>
            </Helmet>
            <main>
                <section className="content-section">
                    <div className="container">
                        <div className={'flex flex-wrap lg:flex-nowrap relative'}>
                            <div className={'basis-full lg:basis-1/2'}>
                                <h1 className={'h1'}>SG-1 Validator Set-up</h1>

                                <div className={'hero-text'}>
                                    As the validator setup we have chosen to use a 5-tier system of nodes, deployed over a range of servers.
                                </div>
                            </div>
                            <div className={'basis-full lg:basis-1/2'}>
                                <div>
                                    <StaticImage className='content-image bigger' placeholder='none' src='../images/validator-illustration.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='setup-section lg:mt-16'>
                    <div className='container bg-boundary'>
                        <div className='flex flex-wrap box-grid'>
                            <h3 className='mb-10 h3 subtitle gradient'>We deploy:</h3>
                            <div className='basis-full'>
                                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2.5'>
                                    <SetupItem text='Open RPC/API Nodes' icon={openIcon} />
                                    <SetupItem text='Sentry Nodes' icon={openSentry} />
                                    <SetupItem text='Relayer Nodes (experimental)' icon={openRelayer} />
                                    <SetupItem text='Validator Nodes' icon={openValidator} />
                                    <SetupItem text='Backup Nodes' icon={openBackup} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-section pt-44 md:pb-20">
                    <div className="container">
                        <div className={'flex flex-wrap items-center'}>
                            <div className={'basis-full md:basis-1/2 md:pr-20'}>
                                <div className={'content-text'}>
                                    <p>
                                        The open RPC/API nodes are designed for services to use. We are using those for our own services, but others can also choose to use them as the IP's are public and we have NGNIX SSL domain routing for them. They are reachable under a public domain aswell. As we scale, this infrastructure will also scale so we can initiate load-balancers in front of an army of open nodes for everyone to use.
                                    </p>
                                    <p>
                                        The other four types of nodes play a crucial part in designing a top secure and high available validator network. <span>The validator name is SG-1.</span>
                                    </p>
                                </div>
                            </div>
                            <div className={'basis-full md:basis-1/2 text-center md:text-right pt-10 md:pt-0'}>
                                <div>
                                    <StaticImage className='max-w-full content-image' placeholder='none' src='../images/validator-image.svg' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <ContactSection/>
        </Layout>
    )
}

export default ValidatorPage;
